.table {
    font-size: .8125rem;
}

.table thead th {
    background: #f6f6ff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: right;
}

.table tbody th {
    background: #fffbf6;
}

.table td {
    text-align: right;
}

.table .total {
    font-weight: bold;
}