.select-container {
    display: flex;
    flex-direction: column;
}

.group-label {
    margin-top: 14px;
    margin-right: 50px;
    padding: 2px 0;
}

.group-label:not(.selected) {
    color:#666666;
}
.group-label.selected {
    font-weight: bold;
}

.select {
    margin: 3px 4px;
    width: unset!important;
}
.select:not(.selected) {
    color: #727272;
}
.select.selected {
    font-weight: bold;
    background-color: #fbfbb8;
}
.select.selected:focus {
    background-color: #fbfbb8;
}