.popup-row {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    flex-wrap: wrap;
}
.popup-item-header {
    width: 106px;
    align-self: flex-start;
}
.popup-row .hint {
    font-size: 0.8125rem;
    color: #999;
    margin-top: 2px;
}

.popup-select { 
    min-width: 140px;
}
.popup-select-wide-container {
    margin-left: 14px;
    width: 100%;
}
.popup-select-wide-container > select {
    width: 100%;
}
.popup-detail-info {
    color: #999;
    margin-top: 2px;
}

.hr-border {
    margin:10px 0 10px 8px;
    background-color:transparent;
    border-top: solid 2px #ddd;
}

.plan-enter {
    opacity: 0;
    max-height:0;
}
.plan-enter-active {
    opacity: 1;
    max-height:260px;
    transition: all 600ms ease-out;
}

.collapse-header {
    padding: 8px 10px;
}
.collapse-header.selected {
    font-weight: bold;
}