.modal-wrapper {
  max-width: 340px;
  margin: auto!important;
}
.modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 180px);
}
.modal-footer {
  display: flex;
  justify-content: center !important;
}