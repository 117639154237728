.last-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.last-viewer {
    background-color: #F2F3F4;
    border: 1px solid #ccc;
    margin-top: 2px;
    min-height: 80px;
    border-radius: 2px;
    padding: 8px 10px;
}
.last-viewer .item {
    display: flex;
}
.last-viewer .item:not(:last-of-type) {
    margin-bottom: 8px;
}
.last-viewer .item .process-kind {
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 1px 4px;
    margin-right: 4px;
    background-color: #FFFFFF;
    flex-shrink: 0;
    align-self: flex-start;
}
.last-viewer .item .info {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.last-viewer .item .lasttime {
    margin-right: 6px;
}
.last-viewer .status-icon {
    margin-right: 2px;
}
.last-viewer .status-icon.success {
    color: #1fa81f;
}
.last-viewer .status-icon.failed {
    color: red;
}
.last-viewer .item .detail-success {
    color: #83aec7;
}
.last-viewer .item .detail-failed {
    color: #ff3333;
}

.executing-msg {
    margin-top: 20px;
}

.input-area {
    margin-top: 8px;
}

.file-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.file-input label {
    margin-bottom: 0;
}
.file-input .button {
    cursor: pointer;
    margin-right: 4px;
}
.file-name {
    font-size: 0.75rem;
}
.file-error {
    color: #ff0000;
    margin-top: 2px;
}

.target-events {
    display: flex;
    flex-flow: row wrap;
}
.target-events > div:not(:last-of-type) {
    margin-right: 20px;
}

.milk-price {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.milk-price input {
    width: 100px;
    margin-right: 4px;
}

.table-header {
    font-size: 0.8125rem;
}
.table {
    margin-top: 4px;
    border: 1px solid #dee2e6;
    font-size: .8125rem;
}
.table thead th {
    background: #f6f6ff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.table .td-robot {
    width: 100px;
}
.table td {
    vertical-align: middle!important;
}
.table .td-cow-changing {
    padding-top: 6px;
    padding-bottom: 6px;
}

.setting-hint {
    color: #999;
    font-size: 0.8125rem;
    margin-top: 6px;
}