.section {
  background: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0.905px 0.724px 0px, rgba(0, 0, 0, 0.035) 0px 2.5px 2px 0px, rgba(0, 0, 0, 0.047) 0px 6.029px 4.823px 0px, rgba(0, 0, 0, 0.07) 0px 20px 16px 0px;
  padding: 0.6rem 0;
  margin: 0.6rem;
  position: relative;
}

.section-padding {
  padding: 0.6rem 0.8rem;
}

.no-padding {
  padding: 0;
}

.date {
  margin: 0;
}
.date > span {
  color: #969696;
  margin-right: 1.5rem;
  font-size: 0.8rem;
}

.bulk-row {
  margin-bottom: 1.0rem;
  display: flex;
  align-items: flex-end;
}

.bulk-row > select {
  width: 174px;
  margin-left: 10px;
  margin-right: 5px;
  height: 1.8rem;
  padding: .1rem .3rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 4px;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.bulk-row > select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a4cdf2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52,143,226,.25);
}
.bulk-row > select.select-error {
  background-color: #ffb9b9;
}

.bulk-row > input {
  width: 68px;
  margin-left: 5px;
  margin-right: 6px;
  height: 1.8rem;
  padding: .1rem .4rem .1rem .1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: right;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 4px;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.bulk-row > input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a4cdf2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52,143,226,.25);
}
.bulk-row > input.input-error {
  background-color: #ffb9b9;
}

.bulk-row-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bulk-row-menu > span {
  margin-right: 4px;
  font-size: 0.8rem;
}


.operation-bar {
  display: flex;
  justify-content: space-between;
  margin: 1.4rem 0.6rem 0;
}
.operation-all {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.operation-all > label {
  margin: 0 6px 0;
  padding-bottom: 4px;
  color: #666666;
}
.operation-checkbox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0.1rem 0rem 0.1rem 0.8rem;
}
.operation-checkbox::before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  background: #8c9094;
  border-radius: 4px;
}
.operation-checkbox:checked::before {
  background: #348fe2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}
.operation-sort {
  display: flex;
  align-items: center;
}
.operation-sort-type {
  color: #83aec7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 0.5rem;
}
.operation-sort-order {
  color: #83aec7;
  border: none;
  background-color: transparent;
  padding: 0.2rem 0.8rem 0.2rem 0.3rem;
  font-size: 1.2rem;
  line-height: 1.5;
  height: 32px;
}


.notice-group {
  margin: 2.2rem 0 0;
  padding: 0 1.0rem 0.2rem;
  font-size: 0.88rem;
  color: #406a9e;
  border-bottom: 1px solid #d2d2d2;
}
.notice-group.first {
  margin: 0.6rem 0 0;
}
.notice-button-container {
  margin-left: 4px;
  flex:1;
}
.notice-button {
  outline: none;
  background-color: #dc2f42;
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  position: absolute;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);
}

.row-checkbox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0.5rem 0.4rem 0.5rem 0;
}
.row-checkbox::before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  background: #8c9094;
  border-radius: 4px;
}
.row-checkbox:checked::before {
  background: #348fe2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.cow-row {
  display: flex;
  border-bottom: 1px solid #d2d2d2;
  justify-content: stretch;
  align-items: center;
  padding: 0.3rem 0.8rem;
  max-width: calc(100vw - 20px - 0.6rem);
}
.cow-row.cow-row-notice {
  background: #fbc7d3;
}
.cow-row.cow-row-exclude {
  background: #c7c7c7;
}

.cow-col-name {
  flex: 0.8;
  min-width: 0;
}
.cow-name {
  display: flex;
  align-items: center;
  min-width: 100px;
  align-items: baseline;
}
.cow-name-align {
  padding-right:  0.5rem;
}
.cow-name > span:nth-child(1)  {
  width: 46px;
  margin-right: 0.2rem;
  font-size: 0.8rem;
  display: inline-block;
  text-align: end;
}
.cow-name > span:nth-child(2)  {
  color: #1d1d1d;
  margin-right: 0.2rem;
  font-size: 0.7rem;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cow-attrs {
  display: flex;
  align-items: center;
  color: #eaeaea;
}
.cow-attrs > span {
  margin-right: 0.2rem;
}
.attr-small {
  font-size: 0.6rem;
}
.attr-house-active {
  color: #3aa8b3;
}
.attr-cation-active {
  color: #ef3a3a;
}
.attr-overeat-active {
  color: #dead40;
}

.cow-col-feeds {
  flex: 1;
  min-width: 0;
}
.feed-row {
  font-size: 0.75rem;
  display: flex;
  justify-content: flex-end;
}
.feed-row > span:nth-child(1)  {
  text-align: end;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 0.15rem;
}
.feed-row > span:nth-child(2)  {
  min-width: 46px;
  width: 46px;
}

.cow-col-menu {
  flex: 0.1;
  display: flex;
  justify-content: flex-end;
}
.smallbutton {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-top: 0.3rem;
}

.nav-container {
  background: #eeeeee;
  height: 38px;
}
.nav-link {
  height: 38px;
}
.nav-container .active {
  background: white!important;
  color: #666666!important;
}

.clear {
  text-align: right;
  margin-right: 8px;
  margin-bottom: 2px;
}