.container {
    display:flex;
    align-items:center;
}

.switch > label {
    width: 36px;
    height: 20px;
    background: #ccc;
    position: relative;
    display: inline-block;
    border-radius: 24px;
    transition: 0.4s;
    box-sizing: border-box;
    margin-bottom: 0;
}

.checked .switch > label {
    background-color: #4BD865;
}

.switch input {
    display: none;
}

.toggle-label {
    margin-bottom: 4px;
    margin-left: 8px;
    font-size: 0.9rem;
}

.checked .toggle-label {
    font-weight: bold;
    color: #464646;
}

.switch > label:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    cursor: pointer;
}

.checked .switch > label:after {
    left: 16px;
}


.toggle-button input {
    display: none;
}
.toggle-button label {
    font-size: 15px;
    cursor: pointer;
}

.toggle-button label > .l {
    padding: 6px 10px;
    border-radius: 6px 0 0 6px;
    background: linear-gradient(to bottom, #F0F0F0 0%, #DDD 100%);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1) inset;
    color: #333;
}
.toggle-button label > .r {
    padding: 6px 10px;
    border-radius: 0 6px 6px 0;
    background: rgb(22, 170, 67);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1) inset;
    color: #FFF;
}
.toggle-button input + label:hover > .l {
    opacity: 0.5;
}
.toggle-button input:checked + label > .l {
    background: rgb(22, 170, 67);
    color: #FFF;
    opacity: 1;
}
.toggle-button input:checked + label > .r {
    background: linear-gradient(to bottom, #F0F0F0 0%, #DDD 100%);
    color: #333;
}
.toggle-button input:checked + label:hover > .r {
    opacity: 0.5;
}