.header {
    display: flex;
}
.header > button {
    width: 74px;
    margin-left: 2px;
}

.selection-info {
    margin-bottom: 8px;
}
.selection-info .cow-count {
    color: #999999;
}
.selection-info .over-limit {
    color: red;
    padding-left: 4px;
}

.body {
    display: flex;
    flex-flow: column nowrap;
}

.cows {
    align-self: center;
}
.cow {
    display: flex;
    margin-top: 14px;
}
.cow .repno {
    min-width: 68px;
}
.cow .edit {
    margin-top: -2px;
    margin-left: 8px;
}
.cow .edit > i {
    padding: 4px;
}

.popup-item {
    margin-bottom: 8px;
}
.popup-lot {
    font-size: 0.75rem;
    margin-left: 24px;
    margin-top: -4px;
    margin-bottom: 2px;
    color: #666666;
}
.popup-invalid {
    text-align: right;
    font-size: 0.8125rem;
    color: red;
    margin-top: 16px;
}