.table-container {
    border-bottom: #ccc solid 1px;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
}
.user-table {
    margin-bottom: 0!important;
}

.user-table tr.selected td + td {
    background-color: #FFE994;
    font-weight: bold;
    color: #464646;
}

.user-table td {
    border: 1px solid #dee2e6;
}

.user-table td.user-select {
    border: none;
    width: 30px;
    vertical-align: middle;
}

.user-table td.user-name {
    width: 30%;
}

.row-checkbox {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
}
.row-checkbox::before {
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    background: #d5dbe0;
    border-radius: 4px;
}
.row-checkbox:checked::before {
    background: #348fe2;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50% 50%;
}

.edit-row {
    display: flex;
    margin-bottom: 14px;
    flex-wrap: wrap;
}
.level-detail {
    display: flex;
    height: 120px;
}

.edit-item-header {
    width: 70px;
}
.edit-item-header-sub {
    font-size: 0.7rem;
    margin-top: -4px;
}

.edit-item-level {
    width: 220px;
}

.edit-item-level-detail {
    height: 100%;
    flex: 1;
    border: 2px solid #ced4da;
    border-radius: 4px;
    padding: 5px 10px;
    overflow-y: scroll;
}

.selected-user-name {
    padding-bottom: 0.5rem;
    margin: 15px 0;
}

.auth-level {
    display: flex;
    flex-direction: column;
}

.auth-level-detail {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.auth-level-detail label {
    margin-bottom: 0;
    margin-left: 5px;
}

.auth-level-detail input[type="checkbox"]:checked + label {
    font-weight: bold;
    color: #464646;
}