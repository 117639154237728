.column-value {
    flex: 1;
    padding-left: 0;
}

.row {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.pref {
    max-width: 200px;
    margin-bottom: 2px;
}