.spinner-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    overflow: hidden;
}
.spinner {
    color: transparent;
    text-indent:-9999em;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    -webkit-animation: spin 0.8s linear infinite;
}
.spinner.default {
    border: 3px solid rgba(183, 212, 255, 0.5);
    border-top-color: #888;
}
.spinner.dark {
    border: 3px solid rgba(240, 240, 244, 0.5);
    border-top-color: white;
}
.spinner.normal {
    width: 40px;
    height: 40px;
}
.spinner.small {
    width: 20px;
    height: 20px;
}
@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}

.error {
    min-height: 38px;
    padding: 4px 6px;
    color: red;
}