.header {
    margin-top: 4px;
    display: flex;
}
.header > button {
    width: 74px;
    margin-left: 2px;
}

.selection-info {
    margin-bottom: 8px;
}
.selection-info .cow-count {
    color: #999999;
}
.selection-info .over-limit {
    color: red;
    padding-left: 4px;
}

.body {
    display: flex;
    flex-flow: column nowrap;
}

.output-summary {
    margin-top: 6px;
}

.cow-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: top;
}
.cow-content > .left {
    padding: 4px;
}

.event-summary {
    color: #999999;
}

.condition-healing-days {
    padding:8px 0;
    font-size: 0.8125rem;
}
.condition-healing-days input {
    width: 80px!important;
    margin-right: 4px;
}
.condition-healing-days div {
    display: flex;
    align-items: center;
}

.popup-series-info {
    font-size: 0.8125rem;
    padding-top: 2px;
}
.popup-header {
    margin: 20px 0 8px;
    font-weight: bold;
    color: #666666;
}
.popup-event-container {
    margin-bottom: 16px;
}
.popup-event-container .treat {
    margin-left: 24px;
    margin-top: 4px;
}
