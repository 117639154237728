.list {
    border: solid 2px #ced4da;  
    
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
  
.list-item {
    border-bottom: dashed 1px #ced4da;
    flex: 1;
    display: flex;
    padding: 10px 0 10px 0;
}
  
.list-item:last-child {
    border-bottom: none;
}
  
.selected {
    background-color: #FFE994;
}