.tag-popup-container .tag-every,
.tag-popup-container .tag-part {
    border: solid 1px #ccc;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-top: 5px;
    padding: 4px;
    min-width: 130px;
    display: flex;
    justify-content: space-between;
}
.tag-popup-container .tag-every > i,
.tag-popup-container .tag-part > i {
    color: red;
    padding: 4px;
}
.tag-popup-container .tag-every {
    background-color: #fff9f9;
    font-weight: bold;
}
.tag-popup-container .tag-part {
    color: #666666;
    border-style: dashed;
}