.mobile-input {
    background-color: white!important;
}

.popup-header {
    /* iOS で行数が多いときにヘッダがつぶれる対処 */
    min-height: 56px;
}

.popup-list {
    width: 100%;
    padding: 0;
    border: solid 1px #ced4da;
    margin-bottom: 0;
}

.popup-list li {
    list-style: none;
    font-size: 0.875rem;
    padding: 12px 8px;
}

.popup-list li:not(:last-of-type) {
    border-bottom: 1px solid #ced4da;
}

.popup-list li.selected {
    background-color: #FFE994;
    font-weight: bold;
}