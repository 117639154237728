.hr-border {
    margin:10px 0 10px 8px;
    background-color:transparent;
    border-top: solid 2px #ddd;
}

.plan-enter {
    opacity: 0;
    max-height:0;
}
.plan-enter-active {
    opacity: 1;
    max-height:260px;
    transition: all 600ms ease-out;
}
