.link {
  color: #fff !important;
  text-decoration: underline !important;
  font-size: 1rem;
  cursor: pointer;
}

.label {
  font-size: 0.9rem !important;
}

.message {
  color: #fff;
  font-size: 0.9rem;
}

input.user-name::placeholder {
  font-size: 0.8em;
}

.error {
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}