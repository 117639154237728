.detail-row {
  align-items: center;
}

.number-input {
  width: 80px!important;
  text-align: center;
}

.label-detail-header {
  text-align: right;
}

.label-total-amount {
  width: 80px!important;
  text-align: right;
  padding-right: 5px!important;
  padding-left: 0px!important;
}

.label-total-price {
  width: 100px!important;
  text-align: right;
  padding-right: 5px!important;
  padding-left: 0px!important;
}

.label-total-count {
  width: 55px!important;
  text-align: right;
  padding-right: 0px!important;
  padding-left: 0px!important;
}

.link-memo {
  margin-left: .8rem;
}

.hr-border {
  width:100%;
  margin:0 0 1rem;
  border:0;
  border-top: solid 1px #ddd;
  height:0;
  background-color:transparent;
}