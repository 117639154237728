.page {
  max-width: 840px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  height: 100%;
  overflow: hidden;
  position: relative;
  grid-template-rows: 1fr 40px;
}

.content-area {
  overflow-x: hidden;
  display: flex;
  position: relative;
  transition: left 0.25s ease 0s;
}

.content-phase {
  width: 100%;
  height: 100%;
  max-width: 33.3333%;
}
.hide {
  overflow-y: hidden;
}

.fix-bottom {
  height: 40px;
  width: 100%;
}


.section {
  background: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0.905px 0.724px 0px, rgba(0, 0, 0, 0.035) 0px 2.5px 2px 0px, rgba(0, 0, 0, 0.047) 0px 6.029px 4.823px 0px, rgba(0, 0, 0, 0.07) 0px 20px 16px 0px;
  padding: 1.2rem 0.8rem;
  margin: 0.6rem;
  position: relative;
}

.footmenu {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cowcount {
  font-size: .8rem;
  margin-right: 1rem;
}

/* Buttons */
.bluebutton {
  color: #fff;
  border: none;
  background-color: #348fe2;
  padding: 0.2rem 0.8rem;
  font-size: .8rem;
  line-height: 1.5;
  border-radius: 4px;
  height: 32px;
}
.bluebutton:hover {
  background-color: #1e7cd2;
}
.bluebutton:disabled {
  background-color: #adadad;
}

.greenbutton {
  color: #fff;
  border: none;
  background-color: #00acac;
  padding: 0.2rem 0.8rem;
  font-size: .8rem;
  line-height: 1.5;
  border-radius: 4px;
  height: 32px;
}
.greenbutton:hover {
  background-color: #008686;
}
.greenbutton:disabled {
  background-color: #adadad;
}

.graybutton {
  color: #000;
  border: none;
  background-color: white;
  border: solid 1px #cacaca;
  padding: 0.2rem 0.8rem;
  font-size: .8rem;
  line-height: 1.5;
  border-radius: 4px;
  height: 32px;
}
.graybutton:disabled {
  background-color: #adadad;
}
.graybutton:hover {
  background-color: #eaeaea;
}

.redbutton {
  color:#fff;
  border: none;
  background-color: #ff5b57;
  padding: 0.2rem 0.8rem;
  font-size: .8rem;
  line-height: 1.5;
  border-radius: 4px;
  height: 32px;
}
.redbutton:disabled {
  background-color: #adadad;
}
.redbutton:hover {
  background-color: #ff3631;
}

/* Loader */
.loader {
  font-size: 10px;
  margin: 20px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  width: 50px;
  height: 50px;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #d2d1d1;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Modal */
.modal-wrapper {
  max-width: 340px;
  margin: auto!important;
}
.modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 180px);
}
.spliter {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: red;
}

.temp-treat-item-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.temp-treat-item-row.invalid {
  color:#ff5b57;
}
.temp-treat-item-row > span {
  margin-right: 6px;
}
.temp-treat-item-row > i {
  margin-left: 10px;
  padding: 1px;
}