.link-right {
    margin-left: 10px;
}

.select-row {
    display: flex;
    align-items: center;
}

.select-row select {
    width: 180px!important;
}

.select-row:not(:last-of-type) {
    margin-bottom: 4px;
}

.cow-row { 
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px 0;
}
.cow-row .cow-label {
    margin-right: 5px;
    min-width: 80px;
}