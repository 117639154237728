.container {
    display: flex;
    height: 32px;
}

.button {
    font-size: 1.1rem!important;
    padding: 0 0 1px 0!important;
    width: 28px!important;
    margin: 1px 0;
    /*
        ボタンの上に中央の入力欄の端が重なってみえる問題の対処
        ※ 2 にしてしまうと、日付選択datepickerの上にボタンが表示されてしまう
    */
    z-index: 1;
}

.input {
    height: 100%!important;
    text-align: center!important;
    -moz-appearance: textfield;
    border-right: 0!important;
    border-left: 0!important;
    border-radius: 0!important;
    padding: 0!important;
    margin: 0 -1px;
    flex: 1;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}