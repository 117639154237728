.search-box {
  margin-bottom: 4px;
}

.search-select-container {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.search-select {
  width: 200px;
  margin-bottom: 2px;
  margin-right: 6px;
}

.schedule-box {
  overflow-y:auto;
  flex: 1;
  padding-bottom: 1px;
}

.schedule-row {
  border: 1px solid #ced4da;
  padding: 0 5px;
  display: flex;
  padding: 10px 5px;
}

.schedule-row:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.schedule-row:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.schedule-row:only-child {
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}

.schedule-row + .schedule-row {
  border-top: none;
}

.schedule-start-date {
  width: 80px;
}

.schedule-status {
  width: 30px;
  padding-top: 0px!important;
  padding-left: 10px;
}

.schedule-status > label::before, .schedule-status > label::after {
  margin-top: -10px;
}

.schedule-sub {
  color: #83aec7;
  font-size: 0.75rem;
  margin-left: 5px;
}

.schedule-cow {
  width: 80px;
}

.schedule-title {
  flex: 1;
}

.schedule-done .schedule-cow, .schedule-done .schedule-title {
  text-decoration: line-through #aaa;
  color: #aaa;
}