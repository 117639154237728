.column-value {
    flex: 1;
    padding-left: 0;
}

.row {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.column-value > .item {
    margin: 2px 0 4px;
}

.block {
    border-radius: 4px;
    background: #F9F9F9;
    border: solid 1px #BBB;
    padding: 6px;
}

.select {
    min-width: 160px;
    max-width: 240px;
}

.watched-at {
    padding-left: 0;
    flex: 1;
    /* 時刻選択プルダウンが個数選択の「+」の裏に表示されないように */
    z-index: 2;
}

.datepicker-popper {
    /* ↑のz-index指定により、カレンダーが時刻プリセットボタンの裏に隠れないように */
    z-index: 3;
}