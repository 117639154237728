.record-container {
    border-radius: 4px;
    margin: -4px -6px 12px;
    padding: 4px 6px;
}
.record-container.disabled {
    background-color: #bbbbbb;
}

.record-header-container {
    display: flex;
    align-items: center;
}

.label-ignore {
    color: #666666;
}
.label-ignore.disabled {
    font-weight: bold;
}

.record-body-container {
    display: flex;
    align-items: flex-start;
}

.amount-container {
    border: solid 1px #999999;
    margin-right: 4px;
    border-radius: 8px;
    padding: 8px;
    flex: 1;
}

.amount-container > ul {
    margin-bottom: 0;
    padding-inline-start: 30px;
}