.cow-title {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center!important;
}

.hr-border {
  width:100%;
  margin:4px 0;
  border:0;
  border-top: dotted 1px #ddd;
  height:0;
  background-color:transparent;
}

.breeding-series-item + .breeding-series-item {
  margin-top: 5px;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
}

.tag {
  margin-right: 2px;
  margin-bottom: 2px;
  border-radius: 4px;
  border: solid 1px gray;
  background: #fff8db;
  padding: 0 4px;
  cursor: pointer;
  white-space: nowrap;
}
.tag > span {
  color: rgba(255, 0, 0, 0.815);
  margin-left: 4px;
  font-weight: bold;
}

.tab-content {
  padding-bottom: 0!important;
}

.event-tab-pane {
  height: 100%;
}

.event-tab-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.event-list-container {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

.event-list-footer {
  display: flex;
  justify-content: flex-end;
  padding: 6px 0;
}