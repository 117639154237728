.section {
  background: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0.905px 0.724px 0px, rgba(0, 0, 0, 0.035) 0px 2.5px 2px 0px, rgba(0, 0, 0, 0.047) 0px 6.029px 4.823px 0px, rgba(0, 0, 0, 0.07) 0px 20px 16px 0px;
  padding: 0.6rem 0;
  margin: 0.6rem;
  position: relative;
}

.section-padding {
  padding: 0.6rem 0.8rem;
}

.no-padding {
  padding: 0;
}

.head {
  display: flex;
  flex-wrap: wrap;
}
.head:not(:last-of-type) {
  margin-bottom: 4px;
}
.head-label {
  color: #969696;
  min-width: 74px;
  font-size: 0.8rem;
}

.tab-pane {
  min-height: 124px;
}
.tab-pane .preset-content {
  margin-bottom: 12px;
}

.bulk-preset-item {
  margin: 0.4rem 0;
  font-size: 0.8125rem;
}

.bulk-row {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}

.bulk-row > .ac-select {
  margin-left: 10px;
  margin-right: 5px;
  width: 200px;
}

.bulk-row > select {
  width: 200px;
  margin-left: 10px;
  margin-right: 5px;
  height: 1.8rem;
  padding: .1rem .3rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 4px;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.bulk-row > select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a4cdf2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52,143,226,.25);
}

.bulk-row > input {
  width: 68px;
  margin-left: 10px;
  margin-right: 5px;
  height: 1.8rem;
}

.bulk-row-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bulk-row-menu > span {
  margin-right: 4px;
  font-size: 0.8rem;
}

.bulk-row > .caption {
  color: #a7a7a7;
}
.bulk-row > label {
  margin-bottom: 0;
}


.operation-bar {
  display: flex;
  justify-content: space-between;
  margin: 1.4rem 0.6rem 0;
}
.operation-all {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.operation-all > label {
  margin: 0 6px 0;
  padding-bottom: 4px;
  color: #666666;
}
.operation-checkbox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0.1rem 0rem 0.1rem 0.8rem;
}
.operation-checkbox::before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  background: #8c9094;
  border-radius: 4px;
}
.operation-checkbox:checked::before {
  background: #348fe2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.notice-group {
  margin: 2.2rem 0 0;
  padding: 0 1.0rem 0.2rem;
  font-size: 0.88rem;
  color: #406a9e;
  border-bottom: 1px solid #d2d2d2;
}
.notice-group.first {
  margin: 0.6rem 0 0;
}
.notice-button-container {
  margin-left: 4px;
  flex:1;
}

.row-checkbox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0.5rem 0.4rem 0.5rem 0;
}
.row-checkbox::before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  background: #8c9094;
  border-radius: 4px;
}
.row-checkbox:checked::before {
  background: #348fe2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.feed-row {
  font-size: 0.75rem;
  display: flex;
  justify-content: flex-end;
}
.feed-row > span:nth-child(1)  {
  text-align: end;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 0.15rem;
}
.feed-row > span:nth-child(2)  {
  min-width: 46px;
  width: 46px;
}

.nav-container {
  background: #eeeeee;
  height: 38px;
}
.nav-link {
  height: 38px;
  min-width: 65px;
  text-align: center;
}
.nav-container .active {
  background: white!important;
  color: #666666!important;
}

.clear {
  text-align: right;
  margin-right: 8px;
  margin-bottom: 2px;
}