.content-header {
    padding-bottom: .5rem!important;
    margin-bottom: 0!important;
    color: #666;
    font-size: 0.9rem;
    text-align: center;
}

.conditions {
    display: flex;
    flex-flow: column nowrap;
    min-height: 122px;
}

.conditions select {
    width: 180px;
}

.condition-row {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.condition-row .condition-presets > button {
    margin-right: 4px;
}
.condition-block {
    border: solid 1px #BBB;
    border-radius: 4px;
    background-color: #F9F9F9;
    display: flex;
    flex-wrap: wrap;
    min-height: 32px;
    padding: 0 8px;
    margin-bottom: 6px;
}
.condition-block:not(:last-child) {
    margin-right: 8px;
}

.load-button-row {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}
.load-button-row button {
    width: 160px;
}

.loaded-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    color: #999;
    margin-bottom: 2px;
}
.loaded-header > div {
    margin: 0 3px;
}

.table-container {
    flex:1;
    min-height: 0;
    overflow-y: auto;
    border: 1px solid #dee2e6;
}

.table {
    font-size: .8125rem;
}

.table thead th {
    background: #f6f6ff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}
.table td {
    max-width: 300px;
    min-width: 52px;
}

.table td.row-header {
    min-width: 78px;
}

.table .tr-total {
    font-weight: bold;
}

.table-footer {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0 8px;
}

.switch-view {
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;
}

.carcass-filter-link {
    font-size: 0.8125rem;
}