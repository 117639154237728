.block {
    border-radius: 4px;
    background: #F9F9F9;
    border: solid 1px #BBB;
    padding: 6px;
}
.block:not(:last-of-type) {
    margin-bottom: 6px;
}
.s-text {
    font-size: 0.8rem;
    padding: 6px 0;
    color: #666666;
}