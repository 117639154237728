.page {
  max-width: 840px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  height: 100%;
  overflow: hidden;
  position: relative;
  grid-template-rows: 1fr 55px;
}

.content-area {
  overflow-x: hidden;
  display: flex;
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
}

.fix-bottom {
  padding-top: 10px;
  height: 55px;
  width: 100%;
}

.content-body {
  overflow-y: auto;
  flex: 1;
}

.row-title {
  margin: 0px 6px;
}

.date-input {
  flex:1;
}

.number-input {
  width: 90px!important;
}

.label-unit {
  padding-left: 5px!important;
  padding-right: 5px!important;
}

.footmenu {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footmenu button + button {
  margin-left: 30px;
}

.cowcount {
  font-size: .8rem;
  margin-right: 1rem;
}

.hr-border {
  width:100%;
  margin:4px 0;
  border:0;
  border-top: solid 1px #ddd;
  height:0;
  background-color:transparent;
}