.content-header {
    padding-bottom: .5rem!important;
    margin-bottom: 0!important;
    color: #666;
    font-size: 0.9rem;
    text-align: center;
}

.row {
    margin-top: 10px;
    margin-bottom: 14px;
    display: flex;
}

.row > select {
    width: 140px;
    margin-right: 10px;
}

.term-selector-row {
    height: 38px;
}

.date-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.date-selector .suffix {
    margin-left: 6px;
    margin-right: 12px;
}
.date-select-controls {
    display: flex;
    align-items: center;
}
.deli-time-select {
    width: 70px!important;
    margin-right: 2px;
}
.date-select-controls > button {
    height: 35px;
    width: 30px;
}

.age-input {
    width: 60px!important;
    text-align: center!important;
}

.load-button-row {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.load-button-row button {
    width: 160px;
}

.graph-header {
    align-items: flex-end;
}

.summary {
    text-align: center;
    color: #999;
    flex: 1;
}

.disptype-selector {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
}
.disptype-selector > div {
    margin-left: 10px;
}

.data-container {
    overflow-x: hidden;
}