.execbutton {
    padding: 0.48rem 0.75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 4px;
    min-width: 90px;
    margin: 0 5px;
}
.primary {
    border: none;
    color: #fff;
    background-color: #348fe2;
}
.primary:hover {
    background-color: #1e7cd2;
}
.primary:disabled {
    background-color: #adadad;
}

.cancel {
    color: #000;
    background-color: white;
    border: solid 1px #cacaca;
}
.cancel:disabled {
    background-color: #adadad;
}
.cancel:hover {
    background-color: #eaeaea;
}

.danger {
    color:#fff;
    border: none;
    background-color: #ff5b57;
}
.danger:disabled {
    background-color: #adadad;
}
.danger:hover {
    background-color: #ff3631;
}