.slim {
    display: none;
}
.wide {
    display: unset;
}
.container {
    background: white;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding-bottom: 6px;
    font-size: 0.8125rem;
}
.slim .container {
    padding-left: .9375rem;
    padding-right: .9375rem;
    padding-top: .9375rem;
}
.wide.container {
    padding-top: 4px;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}
.nav {
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem 5px;
    color: #999;
}
.slide {
    position: absolute;
    top: 30px;
    z-index: -1;
    color: #666;
}
.slide.submit {
    font-weight: bold;
}
.slide.prev {
    left: 4px;
}
.slide.next {
    right: 4px;
}

@media (max-width:991.98px) {
    .slim {
        display: block;
        position: relative;
    }
    .wide {
        display: none;
    }
}

