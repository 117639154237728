.container {
    display: flex;
    flex-flow: row wrap;
}
.container.single {
    align-items: baseline;
    padding: 0 8px;
}
.container.multi {
    align-items: center;
}

@media (min-width: 992px) {
    .container.single {
        justify-content: space-between;
    }
    .container.multi {
        justify-content: center;
    }
    .container .watching {
        flex: 1;
    }
    .container .detail {
        flex: 1;
    }
}

.title {
    margin: 0 12px;
    font-size: 1.2rem;
}
.title .repno {
    color: red;
}
.title .name {
    margin-left: 5px;
}

.detail {
    color: #999;
    font-size: 0.75rem;
}
.detail .four {
    font-size: 0.8125rem;
    padding: 0 1px;
    color: rgb(255, 96, 91);
}

.detail .sex {
    margin-left: 5px;
}

