/*
   1.0 Color system
   2.0 RTL Setting
   3.0 Component Color Setting 
   4.0 Grid & Container Setting
   5.0 Body Setting
   6.0 App Component Setting
   7.0 z-index Setting
   8.0 Font Setting
   9.0 Radius Setting
  10.0 Caret Setting
  11.0 Heading / Paragraph Setting
  12.0 Pagination Setting
  13.0 Button Setting
  14.0 Form Component Setting
  15.0 Input Setting
  16.0 Table Setting
  17.0 Modal Setting
  18.0 List Group Setting
  19.0 Tabs Setting
  20.0 Alert Setting
  21.0 Card Setting
  22.0 Dropown Setting
  23.0 Progress Setting
*/

// 1.0 Color system

$black:       #000000 !default;
$blue:        #348fe2 !default;
$cyan:        #49b6d6 !default;
$dark:        #2d353c !default;
$dark-darker: #1a2229 !default;
$green:       #32a932 !default;
$indigo:      #8753de !default;
$lime:        #90ca4b !default;
$light:       #f2f3f4 !default;
$orange:      #f59c1a !default;
$purple:      #727cb6 !default;
$pink:        #fb5597 !default;
$red:         #ff5b57 !default;
$silver:      #b6c2c9 !default;
$teal:        #00acac !default;
$white:       #FFFFFF !default;
$yellow:      #ffd900 !default;
$inverse:     $dark !default;
$link-color:  $blue !default;
$colors: (
	'black': $black,
	'cyan': $cyan,
	'dark': $dark,
	'dark-darker': $dark-darker,
	'green': $green,
	'indigo': $indigo,
	'lime': $lime,
	'light': $light,
	'orange': $orange,
	'purple': $purple,
	'pink': $pink,
	'red': $red,
	'silver': $silver,
	'teal': $teal,
	'white': $white,
	'yellow': $yellow,
	'muted': darken($silver, 30%),
	'aqua': $cyan,
);
$theme-colors: (
	'dark': $dark,
	'inverse': $dark,
	'black': $black,
	'white': $white,
	'grey': $silver,
	'muted': $silver,
	'silver': $silver,
	'lime': $lime,
	'aqua': $cyan
);
$primary-color:   'teal' !default;
$primary:          map-get($colors, $primary-color) !default;
$success:          $teal !default;
$warning:          $orange !default;

@if $primary-color == 'black' {
	$primary: lighten($dark, 20%);
}



// 2.0 RTL Setting

$enable-rtl: false !default;


// 3.0 Component Color Setting 

$component-active-bg:                           $blue !default;
$component-active-color:                        $white !default;
$custom-control-indicator-checked-disabled-bg:  rgba($blue, .5) !default;


// 4.0 Grid & Container Setting

$grid-breakpoints: (
	xxs: 0px,
	xs: 360px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1660px,
  xxxl: 1900px
);
$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 960px,
  xl: 1170px
) !default;
$spacer:                            rem(16px) !default;
$grid-gutter-width:                 30px !default;


// 5.0 Body Setting

$body-bg:                           #d9e0e7 !default;
$body-color:                        lighten($black, 20%);
$body-letter-spacing:               0 !default;
$border-color:                      lighten($dark, 65%);


// 6.0 App Component Setting

$content-padding-x:                 rem(30px) !default;
$content-padding-y:                 rem(20px) !default;
$content-padding-x-sm:              rem(20px) !default;
$content-padding-y-sm:              rem(20px) !default;
$header-height:                     rem(50px) !default;
$header-height-sm:                  rem(50px) !default;
$top-menu-height:                   rem(40px) !default;


// 7.0 z-index Setting

$btn-scroll-to-top-zindex:          1020 !default;
$content-zindex:                    1020 !default;
$float-submenu-zindex:              1020 !default;
$footer-zindex:                     1020 !default;
$header-zindex:                     1020 !default;
$header-fixed-zindex:               1040 !default;
$sidebar-zindex:                    1010 !default;
$top-menu-zindex:                   1015 !default;
$theme-panel-zindex:                1020 !default;
$page-loader-zindex :               9999 !default;
$page-title-zindex:                 1020 !default;
$pace-loader-zindex:                1030 !default;
$panel-expand-zindex:               1080 !default;


// 8.0 Font Setting

$font-family-base:                  'Open Sans', sans-serif !default;
$font-size-base:                    rem-default(12px) !default;
$font-size-sm:					            rem-default(11px) !default;
$font-size-lg:					            rem-default(14px) !default;
$line-height-base:			            1.5 !default;
$line-height-sm:				            1.4 !default;
$line-height-lg:				            1.8 !default;


// 9.0 Radius Setting

$border-radius:                     4px !default;
$border-radius-lg:                  6px !default;
$border-radius-sm:                  3px !default;


// 10.0 Caret Setting

$enable-caret:                      false !default;


// 11.0 Heading / Paragraph Setting

$headings-font-weight:              600 !default;
$paragraph-margin-bottom:           rem(15px) !default;


// 12.0 Pagination Setting

$pagination-border-width:           1px !default;
$pagination-color:				          lighten($dark, 30%) !default;
$pagination-hover-color:            $dark !default;
$pagination-hover-border-color:     $border-color !default;
$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $component-active-bg !default;
$pagination-focus-box-shadow:       0 !default;


// 13.0 Button Setting

$btn-focus-width:                   0 !default;
$btn-box-shadow:                    0 !default;
$btn-border-width:                  1px !default;


// 14.0 Form Component Setting

$form-component-active-bg:          $blue;
$form-component-active-color:       $blue;
$form-feedback-font-size:           rem(11px) !default;
$form-feedback-tooltip-font-size:   rem(11px) !default;


// 15.0 Input Setting

$input-border-width:                1px !default;
$input-focus-border-color:          #5db0ff !default;
$input-btn-padding-y:               rem(7px) !default;
$input-btn-padding-y-sm:            0.3125rem !default;
$input-btn-padding-x-sm:            0.625rem !default;
$input-btn-font-size-sm:            0.75rem !default;
$input-btn-line-height-sm:          $line-height-sm !default;
$input-color:                       $dark !default;
$input-border-color:                $border-color !default;
$input-group-addon-bg:              $border-color !default;
$input-group-addon-border-color:    $border-color !default;


// 16.0 Table Setting

$table-cell-padding:                rem(8px) !default;
$table-cell-padding-sm:             rem(5px) !default;
$table-accent-bg:                   lighten($dark, 75%) !default;
$table-hover-bg:                    lighten($dark, 75%) !default;
$table-border-color:                lighten($dark, 70%) !default;
$table-component-active-bg:         $blue !default;
$table-component-active-color:      $blue !default;


// 17.0 Modal Setting

$modal-header-padding-y:            rem(15px) !default;
$modal-header-padding-x:            rem(15px) !default;


// 18.0 List Group Setting

$list-group-border-color:           rgba($dark, .125) !default;
$list-group-item-padding-y:         rem(10px) !default;
$list-group-item-padding-x:         rem(15px) !default;


// 19.0 Tabs Setting

$nav-tabs-border-width:             0 !default;
$nav-tabs-link-active-bg:           $white !default;


// 20.0 Alert Setting

$alert-padding-y:                   .9375rem !default; // 15px
$alert-padding-x:                   .9375rem !default; // 15px
$alert-margin-bottom:               1.25rem !default; // 20px
$alert-border-width:                0 !default;


// 21.0 Card Setting

$card-spacer-y:                     rem(15px) !default;
$card-spacer-x:                     rem(15px) !default;
$card-img-overlay-padding:          rem(15px) !default;
$card-columns-gap:                  rem(15px) !default;
$card-border-color:                 rgba($dark, .15) !default;
$card-cap-bg:                       lighten($dark, 75%) !default;


// 22.0 Dropown Setting

$dropdown-border-width:             0 !default;
$dropdown-font-size: 		            $font-size-sm !default;


// 23.0 Progress Setting

$progress-bar-bg:                   $blue !default;
$progress-bg:                       lighten($dark, 70%) !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               none !default;
$progress-bar-color:                $white !default;