.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem 0 0 0.4rem;
  margin-bottom: 2px;
}
.row-level1 {
  background: #bfcdd6;
}
.row-level2 {
  margin-left: 1.6rem;
  background: #e1e5ef;
}
.row-level3 {
  margin-left: 3.2rem;
  background: #f3f4f8;
}
.row > div {
  width: 100%;
}
.row > div > i {
  margin-right: 0.6rem;
}
.row-collapsed > div > i {
  color: #114b4f;
}
.row-expand > div > i {
  color: #78a4af;
}
.row-checkbox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
.row-checkbox::before {
  display: inline-block;
  content: "";
  width: 16px;
  height: 16px;
  background: #8c9094;
  border-radius: 4px;
}
.row-checkbox:checked::before {
  background: #348fe2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}
.row-checkbox.indeterminate::before {
  background: white;
  border: solid rgb(128, 183, 255);
  border-width: 7.5px 4px;
}

.cow-count {
  margin-left: 4px;
  vertical-align: top;
  font-size: 0.75rem;
  font-weight: 600;
  color: #7777bb;
}
