.input-type {
    margin-bottom: 10px;
}

.input-type.checked {
    font-weight: bold;
}

.time {
    margin: 16px 0 2px;
    font-weight: bold;
    color: #83aec7;
    font-size: 0.95rem;
    cursor: pointer;
}

.time-data-container {
    background: #e9ecef;
    border-radius: 6px;
    border: 1px solid #BBB;
    padding: 8px;
}

.time-data-container.enabled {
    background: #F9F9F9;
}

.cow {
    font-weight: bold;
    color: #666666;
}

.cow-data-container:not(:first-child) {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px dashed #CCCCCC;
}

.unit {
    min-width: 16px;
    line-height: 37px;
}