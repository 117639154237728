.header {
    margin-top: 4px;
    display: flex;
}
.header > button {
    width: 74px;
    margin-left: 2px;
}

.selection-info {
    margin-bottom: 8px;
}
.selection-info .cow-count {
    color: #999999;
}
.selection-info .over-limit {
    color: red;
    padding-left: 4px;
}

.body {
    display: flex;
    flex-flow: column nowrap;
}

.output-summary {
    margin-top: 6px;
}

.repno {
    margin-top: 2px;
}
.event-detail {
    padding-top: 4px;
    font-size: 0.75rem;
}