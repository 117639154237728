.list {
  padding: 0;
  position: relative;
}

/* スワイプ可能な領域を広げるため */
.slider {
  height: 100%;
}
.slider > div {
  height: 100%;
  overflow-y: auto;
}

.list-item {
  margin-bottom: 2px;
  padding: 0.5em;
  border-bottom: solid 1px #dadada;
  list-style-type: none;
  display: flex;
}
.list-item.list-item-mid {
  min-height: 40px;
}
.list-item:not(.hidden) {
  border-left: solid 6px #2d8fdd;
  background: #f9fbff;
}
.list-item.hidden {
  border-left: solid 6px #ccc;
  background: #fff;
  color: #999999;
}
.list-item .item-unused {
  text-decoration: line-through;
  color: #aaaaaa;
}
.list-item.fix-height {
  min-height: 52px;
}

.list-item-icon {
  margin-right: 0.6rem;
  align-self: center;
}

.list-item-content {
  flex-grow: 1;
}
.list-item-content-row {
  display: flex;
  flex-wrap: wrap;
}

.list-item-name {
  font-weight: bold;
  word-break: break-all;
}
.list-item-mid .list-item-name {
  font-size: 0.8125rem;
}

.list-item-detail {
  font-size:0.7rem;
}

.disease-icon {
  margin-left: 4px;
  padding: 2px 4px;
}
.disease-icon.add {
  color: #2d8fdd;
}
.disease-icon.remove {
  color: red;
}

.collapsed {
  visibility: collapse;
}

.tab-active {
  height:30px;
  font-weight: bold;
  background-color: #f9fbff;
  text-align: center;
  padding-top: 4px;
  font-size: 1.0rem;
  border-bottom: 2px solid #2d8fdd;
  margin-right: 2px;
}
.tab-default {
  font-size: 0.9rem;
  padding-top: 4px;
  text-align: center;
  height:30px;
  border-bottom: 1px solid #dadada;
  border-right: 5px white solid;
  border-left: 5px white solid;
}

.medicine-route-extra-box {
  border: solid 2px #ced4da;
  padding: 0.5em;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.text-disabled {
  color: #999999;
}

.bookmark-checkbox {
  font-family: "Font Awesome 5 Free";
  font-size: 1.2rem;
  color:#49b6d6!important;
}

.bookmark-checkbox::before {
  content: "\f005";
  font-weight: 400;
}

input[type='checkbox']:checked + .bookmark-checkbox::before {
  content: "\f005";
  font-weight: 900;
}

/* 
  NOTE: react-sortable-hocのModal対応
        並べ替えリストをドラッグするとポップアップの背後に隠れてしまうため、
        z-indexで調整
*/
.sortable-helper {
  z-index: 9999;
}

.header {
  padding-bottom: .5rem!important;
  flex: none;
  margin-bottom: 0!important;
}
.header-with-tab {
  padding-bottom: .5rem!important;
  flex: none;
  margin-bottom: 4px!important;
}

.sortable-list-shoulder {
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.hr-border {
  width:100%;
  margin:6px 0;
  border:0;
  border-top: solid 1px #ccc;
  height:0;
  background-color:transparent;
}

.washout-input {
  width: 70px !important;
}

.popup-unit {
  margin-left: 2px;
}
.popup-prefix {
  margin-right: 2px;
}