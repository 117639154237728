.day-container {
    display: flex;
    flex-wrap: wrap;
}

.event-item {
    display: flex;
}
.event-body {
    flex: 1;
}
.link-right {
    width: 100%;
    text-align: right;
}

.event-his-year {
    position: -webkit-sticky;
    position: sticky;
    top:-1px;
    padding: 2px 0;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: #999;
    margin: 12px 0;
}
.event-his-year > hr {
    margin: -1px 0 -12px;
    border: 0;
    height: 10px;
    width: 100%;
    border-bottom: dashed 1px #999;
    background: white;
}
.event-his-year div {
    border-radius: 12px;
    border: dashed 1px #999;
    margin: 2px 0;
    background-color: white;
    padding: 0 10px;
}

.event-time {
    display: none;
    color: #83aec7;
}

@media screen and (min-width: 768.01px) {
    .event-time {
        display: block;
        width: auto;
    }
}