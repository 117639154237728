.label {
    margin: 0 1rem 0 0;
    text-align: right;
    width: 100%;
}
.title {
  color: #a7a7a7;
  margin: 0 0 0.6rem;
}
.warning {
  margin-left: 2rem;
}

.treat-row {
  margin-bottom: 0.6rem;
}

.physical {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.reexamine > .left,
.physical > .left {
  min-width: 60px;
}
.reexamine > .content-only {
  margin-left: 70px;
}
.reexamine > .content,
.physical > .content {
  margin-left: 10px;
  margin-right: 6px;
}
.reexamine {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;
}

.value-row {
  width: 240px;
}
.value-unit {
  width: 20px;
  display: inline-block;
  margin-right: 6px;
}
.value-row i {
  padding-left: 4px;
  color: palevioletred;
}

.select {
  width: 180px;
  margin-right: 5px;
  height: 1.8rem;
  padding: .1rem .3rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 4px;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a4cdf2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52,143,226,.25);
}

.input {
  width: 74px;
  margin-right: 4px;
  margin-left: 4px;
  height: 1.8rem;
  padding: .1rem .4rem .1rem .1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: right;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 4px;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a4cdf2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52,143,226,.25);
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
.checkbox::before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  background: #8c9094;
  border-radius: 4px;
}
.checkbox:checked::before {
  background: #348fe2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.plus-bar {
  display: flex;
  justify-content: center;
}
.plus {
  color: #d27985;
  font-size: 1.4rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 0.5rem;
}
.plus:disabled {
  color: #d8d8d8;
}

.state-row {
  display: flex;
  margin: 10px 12px;
}
.state-row .header {
  width: 80px;
}
.state-row .state-val {
  flex:1;
}
.state-row .active {
  font-weight: bold;
}