.popup-row {
    display: flex;
    align-items: center;
}
.popup-nested-row-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
}
.popup-item-header {
    width: 80px;
    align-self: flex-start;
}
.popup-preset {
    margin-left: 10px;
    padding: 3px 0;
}
.popup-preset-item {
    font-size: 0.75rem;
    color: #a7a7a7;
}

.popup-checkbox {
    padding: 0!important;
}

.popup-benefit-warning {
    color: #ff3333;
    margin-top: 2px;
    font-size: 0.75rem;
}

.color-sample {
    width: 20px;
    height: 20px;
}

.edited-info {
    font-size: 0.75rem;
    color: #a7a7a7;
    margin-bottom: -12px;
}

.filter-content {
    padding: 1px 0;
}
.filter-event-kind,
.filter-ranch,
.filter-site {
    flex-wrap: nowrap!important;
}
.filter-ranch .item,
.filter-site .item {
    min-width: 180px;
}
.filter-event-kind .item {
    min-width: 120px;
}
