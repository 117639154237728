.nav-container {
  background: #eeeeee;
  height: 38px;
}
.nav-link {
  height: 38px;
}
.nav-container .active {
  background: white!important;
  color: #666666!important;
}
.tab-content {
  margin-bottom: 0!important;
  height: calc(100% - 38px);
}

.list-item-content {
  display: flex;
  flex-grow: 1;
  min-height: 30px;
  align-items: center;
}
.list-item-name {
  font-weight: bold;
  flex-grow: 1;
  margin-left: 2px;
}

.event-icon {
  font-size: 1.1rem;
  padding: 2px 6px;
  color: #cccccc;
}
.applied {
  color: #1fa81f;
}
.part-applied {
  color: #f8e322;
}
.btn-detail {
  margin-right: 6px;
}

.modal-content .row {
  margin-top: 12px;
}
.modal-content .row.inline {
  display: flex;
  align-items: center;
}
.modal-content .row-sub {
  margin-top: 6px;
}
.right {
  text-align: right!important;
}
.modal-content .label {
  font-weight: bold;
  color:#666666;
}