.conditions {
    display: flex;
    flex-flow: column nowrap;
    min-height: 88px;
}

.conditions .label {
    min-width: 64px;
}
.conditions .select {
    flex: 1;
    max-width: 320px;
}
.condition-row {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.view-root {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    min-height: 0;
}

.history-view-header {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4px;
}
.history-view-header .summary {
    color: #999999;
}
.history-view-header .switch {
    display: flex;
}