.column-value {
    flex: 1;
    padding-left: 0;
}

.row {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.time-preset-container {
    display: flex;
    flex-flow: column nowrap;
}
.time-preset {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.time-preset-name {
    width: 70px!important;
    margin-right: 5px;
}
.collapse {
    margin-bottom: 16px;
}