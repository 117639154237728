.page {
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  overflow: hidden;
}

.content-area {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
}

.fix-bottom {
  height: 60px;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}

.board {
  position: relative;
  background: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0.905px 0.724px 0px, rgba(0, 0, 0, 0.035) 0px 2.5px 2px 0px, rgba(0, 0, 0, 0.047) 0px 6.029px 4.823px 0px, rgba(0, 0, 0, 0.07) 0px 12px 16px 0px;
  margin: 0.8rem 2rem 1.2rem 2rem;
  padding-bottom: 0.2rem;
}
.boardheader {
  background-color: #000000;
  background-image: linear-gradient(45deg, #000000 0%, #99a5ad 100%);
  border-radius: 6px 6px 0 0;
  color: #e6e6e6;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.8rem 0.8rem 0.2rem;
}
.board > div {
  overflow-y: auto;
  transition: all 400ms 0s ease;
}
.board > div:not(.expand) {
  height: 146px;
}
.board > div.expand {
  height: calc(100vh - 260px);
}
.board .expander {
  position: absolute;
  bottom: 2px;
  right: 10px;
  border-radius: 50%;
  background: #eee;
  opacity: 0.4;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
.board .expander:hover {
  opacity: 0.6;
}
.board .expander > i {
  margin: auto;
  font-size: 1.2rem;
  color: #999;
}
.board .schedule {
  display: flex;
  padding-right: 4px;
}
.board .schedule-body {
  flex: 1;
}
.board .schedule .tomorrow {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  margin-left: 2px;
}
.board .checkbox {
  margin-right: 2px;
  padding-top: 0px!important;
}
.board .checkbox > label::before, .board .checkbox > label::after {
  margin-top: -10px;
}
.board .item {
  margin: 0;
  padding: 0.2rem 1.2rem;
  font-size: 0.8rem;
  margin-bottom: 0.6rem;
}
.board .item.activity {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.board .more {
  text-align: center;
  padding: 6px 20px;
}

.board .item .undo {
  color: #FF6666;
  margin-left: 10px;
}
.board .item .undo i {
  padding: 2px;
}
@media screen and (max-width: 768px) {
  .board .item.activity .content {
    flex: 1;
  }
}
@media screen and (min-width: 768.01px) {
  .board .item.activity .content {
    margin-right: 15px;
  }
}
.board .content.undone {
  /* ※ショートハンドで指定すると、thicknessが含まれる場合にiOS safariで有効にならない */
  text-decoration-color: #aaa;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
  text-decoration-style: solid;
}
.board .undone .info,
.board .undone .time {
  color: #aaa;
}

.board .time {
  font-weight: bold;
  color: #636363;
}
.board .sentence {
  margin: 0 0.3rem;
  font-weight: 500;
  color: #636363;
  margin-bottom: 0.6rem;
}
.board .schedule-body-done .time {
  text-decoration: line-through #aaa;
  color: #bbb;
}
.board .schedule-body-done .sentence {
  text-decoration: line-through #aaa;
  color: #aaa;
}

.board .info {
  font-weight: 500;
  color: #636363;
}
.board .date {
  color: #9a9a9a;
  margin-left: 1.2rem;
  margin-bottom: 0.4rem;
}
.board hr {
  height: 1px;
  background: transparent;
  border-top: 1px dotted #cccccc;
  margin: 0 1.2rem 0.6rem;
}
.board .watching {
  margin: 0 0.3rem;
  padding: 0.2rem 1.2rem;
  margin-bottom: 0.6rem;
}
.board .washout-term, .board .watchingmemo {
  margin-left: 0.6rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #9a9a9a;
}
.board .has-record {
  color: #83aec7;
}

.menuheader {
  margin: 3.2rem 1rem 0;
  border-bottom: solid 1px #b5b5b5;
  color: gray;
  text-align: center;
  letter-spacing: 1rem;
}

.memoareaheader {
  margin-top: 30px;
}

.memoarea {
  margin: 0.8rem 1.4rem 0.4rem;
  overflow-y: auto;
  max-height: 280px;
  display: flex;
  flex-direction: column;
}

.memo {
  background: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0.905px 0.724px 0px, rgba(0, 0, 0, 0.035) 0px 2.5px 2px 0px, rgba(0, 0, 0, 0.047) 0px 6.029px 4.823px 0px;
  margin: 0.2rem 0.6rem 0.8rem;
  padding: 6px;
}

.memoheader {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  margin-bottom: 2px;
}
.memoheader .date {
  color: #9A9A9A;
  font-weight: bold;
  font-size: 0.75rem;
}
.memoheader .date > span {
  font-weight: normal;
}
.memoheader .icon {
  margin-top: -2px;
  color: #666666;
  font-size: 0.9rem;
}
.memoheader i {
  margin: 0 6px;
}
.memo .text {
  padding: 6px;
}

.addmemo {
  align-self: center;
  margin-bottom: 2px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  margin-bottom: -2px;
}
.buttons > button {
  margin-left: 4px;
}

.morenote {
  align-self: center;
}