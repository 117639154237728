.cow-popup-basic-info > div {
    margin-bottom: 6px;
}

.event-row {
    display: flex;
}

.event-row-header {
    min-width: 60px;
}

.hr-border {
    width:100%;
    margin:4px 0;
    border:0;
    border-top: dotted 1px #ddd;
    height:0;
    background-color:transparent;
}

.header-ranch-icon {
    width: 30px;
}

.notification {
    position: relative;
}

.notification:before {
    font-style: normal;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0f3";
}

@media (max-width:991.98px) {
    .request:after{
        content:"";
        position: absolute;
        background: tomato;
        height:6px;
        width:6px;
        top:5px;
        right:-5px;
        text-align: center;
        border-radius: 50%;
    }
}

@media (min-width:991.99px) {
    .request:after{
        content:"";
        position: absolute;
        background: tomato;
        height:6px;
        width:6px;
        top:12px;
        right:-5px;
        text-align: center;
        border-radius: 50%;
    }
}

/* accept-popup */
.modal-wrapper {
    max-width: 300px;
    margin: auto;
}

.modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
}

.acceptrow {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.6rem;
}

.authorityrow {
    font-size: .9rem ;
    text-align: right;
    margin-bottom: 0.6rem;
}

.header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    margin-bottom: 0;
    padding: 0;
    -webkit-transition: box-shadow .2s linear;
    -moz-transition: box-shadow .2s linear;
    -ms-transition: box-shadow .2s linear;
    -o-transition: box-shadow .2s linear;
    transition: box-shadow .2s linear;
    display: flex;
    flex: 1;
    background-color: white;
    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, .15);
    box-shadow: 0 0 16px rgba(0, 0, 0, .15);
}

.container {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 60px;
}
.container:not(.container-search) {
    justify-content: space-between;
}
.container.container-search {
    justify-content: flex-end;
}
.search {
    margin-right: 10px;
    display: flex;
    align-items: center;
}
.search > span {
    color: #999;
}
.search-select {
    width: 160px;
    margin-left: 4px;
}
.search-close {
    margin-left: 10px;
    padding: 10px;
    color: #999;
}
.option-trace {
    font-size: 0.7rem;
    color: #999999;
}
.option-trace .input {
    font-weight: bold;
    color: #333;
}

.back {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.back:not(.shown) {
    visibility: hidden;
}

.title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 0.96rem;
}

.icons {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-right: 15px;
    align-items: center;
    font-size: 1.25rem;
}

.dd {
    height: 100%;
    display: flex;
    align-items: stretch;
}
.icons > div:not(:first-child) {
    margin-left: 15px;
}

.icons .ddtoggle {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 14px;
}

.dropdown-menu {
    border-top: solid 3px #00acac!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    margin-top: -1px!important;
    width: 22.5rem;
    padding: 0!important;
}
.dropdown-menu > div {
    max-height: calc(100vh - 150px);
    display: flex;
    flex-flow: column nowrap;
}

@media (min-width:768.01px) {
    .container {
        margin: 0 20px;
    }
    .icons > div:not(:first-child) {
        margin-left: 30px;
    }
}

.user-img {
    width: 2.25rem;
    height: 2.25rem;
    -webkit-border-radius: 120px;
    border-radius: 120px;
}

.cart-item>li>div {
    display: table-cell;
    vertical-align: middle
}

.cart-body {
    overflow-y: auto;
}

.cart-body,
.cart-footer,
.cart-header {
    padding: .9375rem
}
.cart-header.teams {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.cart-body+.cart-footer,
.cart-header+.cart-body {
    border-top: 1px solid #dee2e6
}

.cart-header.teams .user-info {
    display: flex;
    align-items: center;
}
  
.cart-item {
    list-style-type: none;
    margin: 0;
    padding: 0
}
  
.cart-item>li {
    display: table;
    width: 100%
}
  
.cart-item>li+li {
    padding-top: .625rem;
    border-top: 1px solid #dee2e6;
    margin-top: .625rem
}
  
.cart-item>li h4 {
    font-size: .875rem;
    margin: .1875rem 0
}
  
.cart-title {
    font-weight: 600;
    font-size: .875rem;
    margin: 0
}
  
.cart-item-info {
    width: 80%
}

.version {
    position: absolute;
    top: 2px;
    right: 6px;
    color: #aaa;
    font-size: 0.8rem;
}

.cart-body {
    background-color: #f6f6f6;
}

.team-list {
    list-style-type: none;
    padding: 0;
}
.team-list li {
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-wrap: nowrap;
    min-height: 60px;
    padding: 6px 4px;
}
.team-list li+li {
    margin-top: 8px;
}
.team-list li:not(.joining) {
    box-shadow: 0 0 4px -1px #999;
}
.team-list li.joining {
    border: dashed 1px #aaa;
    background-color: #fbfbfb;
}
.team-list li.selectable {
    cursor: pointer;
}
.team-list li.selectable:hover {
    box-shadow: 0 0 5px -1px #666;
}
.team-list li.current {
    border: solid 1px #aaa;
    background-color: #ffffe2;
}
.team-list li.current .team-name {
    font-weight: bold;
}
.team-list li.selectable .team-name {
    color: #348fe2;
}
.team-list .team-icon {
    width: 30px;
    height: 30px;
}
.team-list .team-icon .ranch {
    max-width: 100%;
    max-height: 100%;
}
.team-list .team-icon .clinic {
    padding: 0 2px;
}
.team-list .team-info {
    flex: 1;
    margin-left: 10px;
}
.team-list .team-info .team-name {
    font-size: 0.875rem;
    margin: 2px 0;
}
.team-list .team-info .symbol {
    font-size: 0.75rem;
    color: #999;
}
.team-list .team-action {
    color: #666;
    font-size: 20px;
    padding: 0 6px 4px;
    align-self: flex-start;
}
.team-list .team-action:not(:last-of-type) {
    margin-right: 4px;
}

.add-unofficial,
.search-team {
    padding: 12px 0;
    text-align: center;
}
