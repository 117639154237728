.symptom-series-item + .symptom-series-item {
    margin-top: 5px;
    border-top: 1px solid #dee2e6;
    padding-top: 5px;
}

.symptom-status {
    margin-left: 0px !important;
    margin-right: 10px;
}

.symptom-status:last-child {
    margin-right: 0px;
}

.hr-border {
    margin:10px 0 10px 8px;
    background-color:transparent;
    border-top: solid 2px #ddd;
}

.plan-enter {
    opacity: 0;
    max-height:0;
}
.plan-enter-active {
    opacity: 1;
    max-height:200px;
    transition: all 500ms ease-out;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}
.row-collapse {
    display: flex;
    flex-wrap: wrap;
}
.group {
    margin-bottom: 16px;
}
.group:not(:first-of-type) {
    margin-top: 16px;
}

.disease-block {
    border-radius: 4px;
    background: #F9F9F9;
    border: solid 1px #BBB;
    padding: 6px;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 6px;
}
.disease-block .cause {
    display: flex;
    align-items: center;
    margin-top: 7px;
}
.disease-block .cause > select {
    flex: 1;
}
.disease-block .cause .code {
    min-width: 70px;
    font-weight: bold;
    color: #666666;
    padding-left: 5px;
}