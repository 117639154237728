.description {
    font-size: 0.8125rem;
}

.table {
    border-bottom: solid 1px rgb(222, 226, 230);
}
.table td {
    vertical-align: middle!important;
}
.table td.unchecked {
    color: #b0b0b0;
}
.table td.ranch {
    color: #83aec7;
}
.table + .hint {
    color: #83aec7;
    margin-top: -6px;
    margin-bottom: 10px;
    font-size: 0.8125rem;
}
.tag {
    margin-right: 2px;
    border-radius: 4px;
    border: solid 1px gray;
    background: #fff8db;
    padding: 0 4px;
    word-break: break-all;
}
.cell-tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.cell-tag > span {
    font-size: 0.8125rem;
}

.checkcell {
    padding: 5px 0!important;
}

.group {
    margin-top: 12px;
    padding: 6px;

}
.group > .label {
    font-weight: bold;
    color: #999;
    margin-left: 0.3rem;
    margin-bottom: 2px;
}