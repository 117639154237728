.group-label {
    font-weight: bold;
    color: #666666;
    margin-left: 4px;
    margin-top: 30px;
}
.group-label:first-of-type {
    margin-top: 0;
}

.group {
    border: 1px solid #BBB;
    border-radius: 6px;
    padding: 8px;
    background: #F9F9F9;
}

.row {
    display: flex;
    align-items: top;
    margin-bottom: 10px;
}
.row:last-of-type {
    margin-bottom: 0;
}

.row .header {
    width: 110px;
    padding-top: 10px;
}

.row .content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: flex-start;
}

.row .age {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 15px;
}

.row .age .input {
    width: 80px;
    margin: 0 2px;
}