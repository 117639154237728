.day-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.event-item {
    display: flex;
}

.event-body {
    flex: 1;
}

.event-time {
    display: none;
    color: #83aec7;
}

@media screen and (min-width: 768.01px) {
    .event-time {
        display: block;
        width: auto;
    }
}