.s-text {
    font-size: 0.8rem;
    padding: 6px 0;
    color: #666666;
}

.output-row {
    display: flex;
    align-items: center;
}


.output-days {
    width: 74px!important;
    margin-left: 2px;
    margin-right: 2px;
}

.output-group {
    border: 1px solid #aaa;
    border-radius: 6px;
    padding: 16px 8px 4px;
    margin-bottom: 20px;
    margin-top: -12px;
}
.output-group-items {
    display: flex;
    flex-wrap: wrap;
}

.output-group-label {
    margin-left: 10px;
    margin-bottom: 0;
    background-color: white;
    padding: 0 4px 0;
}

.output-bool-check {
    min-width: 100px;
    margin-right: 10px;
}

.condition-itemset {
    border-radius: 4px;
    background: #F9F9F9;
    border: solid 1px #BBB;
    padding: 6px;
}
.condition-itemset:not(:last-of-type) {
    margin-bottom: 6px;
}