.interval-days-input {
  margin: 0 5px 0 10px;
  width: 80px!important;
}

.condition-item {
  padding-left: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
}
.condition-item > .header {
  min-width: 110px;
}
.condition-item > .content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding-bottom: 15px;
}
.condition-item > .content-only {
  margin-left: 110px;
}
.condition-item .treat {
  width: 220px;
}

.collapse {
  margin-top: 30px;
  margin-left: -6px;
  padding-bottom: 5px;
  font-weight: bold;
  color: #aaa;
}

.popup-label {
  width: 110px;
  flex-shrink: 0;
}

.item-modal {
  max-width: 460px!important;
}
.item-modal-block {
  display: flex;
  flex-wrap: wrap;
}
.item-modal-block label {
  width:106px;
}
.item-modal-block .content {
  flex:1;
}

.item-modal-eventkind {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.item-modal-eventkind select {
  width: 140px!important;
}

.item-modal-day-after {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}