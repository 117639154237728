body {
    height: 100%;
    /* background-color:#0073BC */
}

#root {
    height: 100%;
}
#page-container {
    height: 100%;
}

@media (max-width: 768px) {
    .content {
        padding: 10px;
    }
}

.footer {
    position: fixed!important;
    margin: 0px!important;
    width: 100%!important;
    height: 60px!important;
    padding: 0px!important;
    bottom: 0px;
}

.search-item-container .item-row .item {
    width: 100%;
}

.content-header {
    position: fixed;
    padding:10px;
}

.button-page-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 350px;
    margin: auto;
    padding: 10px 0 0;
}


/* 順次廃止 ---------- */
@media (max-width: 768px) {
    .content-footer {
        padding:10px 20px 0;
    }
}
@media(min-width:768.01px) {
    .content-footer {
        position: fixed;
        left: 30px;
        right: 30px;
        padding:10px;
    }
}
.content-footer .btn-row {
    text-align: center;
}
/* ------------------- */

/*
    ※影響範囲が確認しきれていないので残してあるが、
    　実際には flexboxによって高さが決まっており
    　height設定は効いていない
*/
@media (max-width: 768px) {
    .ranch-main {
        overflow-y:auto;
        height:calc(100% - 110px)!important;
    }
}
@media (min-width:768.01px) {
    .ranch-main {
        overflow-y:auto;
        height:calc(100% - 140px);
    }
}

@media (max-width: 768px) {
    .right-menu-icon {
        margin-top: 10px;
    }
}

/* TODO .product-body に置き換え */
.feed-write {
    overflow-y:auto;
}

.page-root {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;    
}
.page-root.width-limit {
    max-width: 840px;
    margin: 0 auto;
}
.product-full-height {
    flex: 1;
    min-height: 0;
}
.product-body {
    overflow-y: auto;
    flex: 1;
}
.row-no-margin {
    display: flex;
    flex-wrap: wrap;
}
.modal-input-block > label.label-m {
    min-width: 100px;
    max-width: 110px;
}
.modal-input-block > label.label-s {
    min-width: 60px;
    max-width: 100px;
}
.modal-input-block input {
    width: auto;
}

/* 順次廃止 ---------- */
.product-with-footer {
    height: calc(100% - 50px);
}
.page-footer {
    height: 50px;
}
/* ------------------- */

.product-info-fix {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.treat-write-row {
    margin-left: 0px!important;
    margin-right: 0px!important;
}
.treat-block-header {
    margin: 24px 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.treat-block-header > label {
    padding-left: 0px;
    margin-right: 20px;
    margin-bottom: 0;
    font-weight: bold;
    color: #666666;
    font-size: 0.875rem;
}

.white-tab-content {
    padding-top:0!important;
    overflow-y:auto;
    margin-bottom:0!important;
    flex: 1;
}

.white-tab .active {
    background: white!important;
    color: #666666!important;
}

.form-input-number {
    padding-left:6px;
    padding-right:6px;
    text-align: right;
}

.color-div-center {
    display: table;
    height: 6em;
    width: 100%;
    text-align: center;
    font-size: 12px;
}
.color-span-center {
    display: table-cell;
    vertical-align: middle;
}

.panel {
    margin-bottom: 0px;
}

.action-icon-bar {
    overflow-x:auto;
    overflow-y:hidden;
    display:flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.action-icon-bar-arrow {
    z-index: 10;
    font-size: 1.2rem;
    color:rgb(178,32,32);
}
.action-icon-bar-arrow.l {
    margin-left: -5px;
    margin-right: -5px;
}
.action-icon-bar-arrow.r {
    right: 5px;
}
.action-icon-bar::-webkit-scrollbar {
    display:none;
}
.event-button-container {
    display:flex;
    flex-direction:column;
    align-items:center;
    cursor: pointer;
}
.action-icon-bar .event-button-container {
    min-width: 70px;
}
.rc-tooltip .event-button-container {
    min-width: 60px;
}
.event-button-container .event-icon {
    color:green;
    font-size:1.1rem;
}
.event-button-container .event-icon:hover {
    color:rgb(0, 150, 0);
}
.event-button-container .event-icon:active {
    color:rgb(0, 87, 0);
}
.event-button-container .event-text {
    font-size:0.7rem;
    text-align:center;
    line-height:1.2;
    user-select: none;
}


.link {
    color: #0033CC!important;
    text-decoration: underline #a3beff!important;
    cursor: pointer;
}
.link.invalid {
    color: red!important;
}
.link.disabled {
    pointer-events: none;
    color: #8b99be!important;
    text-decoration: underline #a0add1!important;
    cursor: inherit;
}
.link:active {
    color: #999999!important;
}
.clickable {
    cursor: pointer;
}
.clickable:hover {
    color: #999999!important;
}
.clickable:active {
    color: #777777!important;
}

.fc-button {
    line-height: 1.0;
}

.fc-cow .fc-toolbar {
    justify-content: flex-start;
}
.fc-cow .fc .fc-toolbar.fc-header-toolbar h2 {
    font-size: 1rem;
    margin-bottom: 0;
    text-align: center;
    width: 40px;
    margin-top: 2px;
}

.fc-cow .fc-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fc-list-heading {
    line-height: 1.3;
}

.fc-day-grid-event .fc-content{/*--ver 3.9.0 word wrap------*/
    white-space: normal!important;
    word-wrap:break-word!important;
}

.fc-header-toolbar{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index:9;
    background-color:white;
    border-top:    1px solid lightgray;
    border-bottom: 1px solid lightgray;

}
.fc-head{
    z-index:10;
    background-color:#e9e9e9;/*--背景色--*/
}

.fc-ranch .fc .fc-toolbar.fc-header-toolbar h2 {
    font-size: 1rem;
    margin-bottom: 0;
    text-align: center;
    margin-top: 2px;
}

.fc-ranch {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 480px) {
    .fc-event {
        font-size:0.6rem;
        margin-bottom: 2px;
    }
    .fc-day-number {
        font-size: 0.65rem;
    }
    .fc-day-top {
        line-height: 1.3;
    }
}

.icon-tooltip .rc-tooltip-inner {
    background: rgb(255, 245, 230);
}
.icon-tooltip .event-text {
    color: #333333;
}
.rc-tooltip.icon-tooltip {
    background: transparent!important;
}
.icon-tooltip .rc-tooltip-arrow {
    /* left: 10%!important; */
}
.optional-input {
    padding: 10px 10px;
    border-radius: 6px;
}
.optional-input.unselected {
    background: #dddddd;
    color: #999999;
}
.optional-input.unselected input {
    color:#999999;
}

.rc-tooltip.quick-search {
    background: transparent!important;
    opacity: 1;
}
.quick-search .rc-tooltip-inner {
    background: white;
    color: #333333;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1!important;
    transition: all 400ms ease-out;
}
.fade-exit {
    opacity: 1!important;
}
.fade-exit-active {
    opacity: 0!important;
    transition: all 400ms ease-out;
}
.fadein-enter {
    opacity: 0;
}
.fadein-enter-active {
    opacity: 1!important;
    transition: all 400ms linear;
    transition-delay: 100ms;
}
.fadein-exit {
    opacity: 1!important;
}
.fadein-exit-active {
    opacity: 0!important;
    transition: none;
}

.modal-slim {
    max-width: 300px;
    margin: auto;
}

/* ColorAdmin の印刷スタイルを上書き */
@media print{
    @page {
        size: unset!important;
    }
}

.dropdown.show .arrow-top {
    border:solid 7px transparent;
    border-bottom: solid 7px #00acac;
}
.dropdown.show .arrow-spacer {
    height: 14px;
}

.modal-footer-fix {
    justify-content: center;
}

.borderless-select {
    color: #83aec7;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
}

.chart-legend {
    position: absolute;
    left: 10px;
    top: 8px;
    border: solid 1px #666666;
    border-radius: 6px;
    background: white;
    opacity: 0.8;
    padding: 5px 6px 3px;
}