.list-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.list-item {
  border-left: solid 6px #2d8fdd;
  background: #f9fbff;
  margin-bottom: 2px;
  padding: .3rem .5rem;
  border-bottom: solid 1px #dadada;
  list-style-type: none;
  display: flex;
  height: 55px;
}

.list-item-icon {
  margin-right: 0.6rem;
  align-self: center;
}

.list-item-content {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.list-item-name {
  font-weight: bold;
  align-self: center;
}

.icon-disabled {
  color: #aaa;
  cursor: default;
  pointer-events: none;
}