.search-box {
  outline: none;
  font-family: inherit;
  background: #ededed url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" width="16"><path fill="%23cccccc" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" /></svg>') no-repeat 9px center;
  box-sizing: border-box;

  border: solid 1px #ccc;
  padding: 2px 10px 2px 32px;
  height: 34px;
  width: 146px;

  -webkit-border-radius: 18px 0 0 18px;
  -moz-border-radius: 18px 0 0 18px;
  border-radius: 18px 0 0 18px;

  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}
.search-box:focus {
  width: 200px;
  background-color: #fff;
  border-color: #57d6d6;
  box-shadow: 0 0 5px #a8cece;
}

.search-detail {
  color: #fff;
  background-color: #00acac;
  border-color: #ccc;
  height: 34px;
}
.search-detail.cow {
  margin-left: 0;
  border-width: 1px 1px 1px 0;
  border-radius: 0 18px 18px 0;
  padding: 2px 8px;
  width:110px;
}
.search-detail.house {
  border-width: 1px;
  border-radius: 18px;
  padding: 2px 14px;
}

.search-detail:focus {
  outline:none;
}

.footer {
  padding:8px 15px 0;
  margin-bottom: -6px;
}

.watching {
  background-color: #ffeeee !important;
}

.row-checked {
  background: #e4eeff;
}

.rowdata-check, 
.rowdata-text {
  padding-left: 8px!important;
  padding-right: 8px!important;
}
.rowdata-text {
  min-width: 64px;
  max-width: 220px;
}
.rowdata-icon {
  padding: 0!important;
  vertical-align: middle!important;
}

.table {
  border: 1px solid #dee2e6;
}

.table thead th {
  background: #f6f6ff;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table thead th > div {
  display: flex;
  align-items: center;
}

.cell-tag {
  display: flex;
  flex-wrap: wrap;
}
.tag {
  margin-right: 2px;
  border-radius: 4px;
  border: solid 1px gray;
  background: #fff8db;
  padding: 0 4px;
  cursor: pointer;
  word-break: break-all;
}