.overlay-root {
    max-height: 380px;
    overflow-y: auto;
}

.overlay-row {
    margin-top: 2px;
    border-bottom: 1px dotted #CCCCCC;
}
.overlay-row.clickable {
    cursor: pointer;
}
.overlay-row.checkable {
    display: flex;
    align-items: center;
    min-height: 32px; 
}
.overlay-row.checkable > input {
    margin-bottom: 1px;
}
.overlay-row.checkable > label {
    margin-left: 6px;
    margin-bottom: 0;
}
.overlay-row .watching {
    margin-left: 3px;
}
.overlay-row .watching:not(.memo) {
    color: #ff3333;
}
.overlay-row .watching.memo {
    color: #ffc666;
}
.overlay-row .single {
    margin-left: 14px;
    padding: 2px;
    margin-right: 4px;
    font-size: 0.9rem;
}
.hidden {
    visibility: hidden;
}

