.menu-group {
    border: 1px solid #0073BC;
    text-align: left;
    border-radius: 0px;
    overflow: hidden;
    margin: 6px 6px 12px;
}
.menu-group-label {
    color: #fff;
    background: #0073BC;
    padding: .3em .8em;
    font-weight: bold;
}
.menu-group ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.menu-group ul li {
    padding: 0;
    margin: 0;
}
.menu-group ul li span {
    color: #273bad;
    display: block;
    padding: .7em 1.2em;
    border-bottom: 1px dotted #0073BC;
}
.menu-group ul li:last-child span {
    border: none;
}