.modal {
    max-width: 100%!important;
    margin: auto 15px!important;
}

.title {
    font-weight:bold;
    color:#666666;
    font-size:1.1rem;
    margin-bottom:18px;
    text-align:center;
}

.body {
    font-size: 0.8rem;
    word-break: break-all;
}

.chapter {
    margin-top: 14px;
}

.chapter-title {
    font-size: 0.8rem;
    margin-bottom: 2px;
    font-weight: bold;
}

.body-list {
    padding-left: 8px;
    list-style-type: none;
    counter-reset: item;
}

.body-list > li {
    text-indent: -1.2rem;
    padding-left: 1.2rem;
}

.body-list > li:before {
    counter-increment: item;
    content: '('counter(item)') ';
}

.subchapter-list,
.privacy-list {
    padding-left: 16px;
}

.subchapter-list > li {
    margin-bottom: 6px;
}

.privacy-list > li {
    margin-top: 12px;
}

.child-list {
    padding-left: 24px;
    list-style-type: upper-alpha;
    text-indent: 0;
}

.separator {
    margin-bottom: 32px;
    margin-top: 40px;
    height: 10px;
    background-color: #003355;
    margin-right: -15px;
    margin-left: -15px;
}

.footer {
    margin-top: 14px;
    font-size: 0.7rem;
}