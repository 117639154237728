.content {
    overflow-y: auto;
}

.selltype-container {
    border-top: 1px solid rgb(221,221,221);
}
.selltype-label {
    font-weight: bold;
    color: #aaa;
    font-size: 0.875rem;
}
.selltype-label.multi {
    margin: 15px 0 15px;
}
.selltype-label.single {
    margin: 5px 10px 0;
}
.col-input-num {
    display: flex;
    align-items: center;
}
.col-input-num input {
    width: 110px!important;
    margin-right: 6px;
}
.col-input-wrapper .addition {
    margin-top: 3px;
}
.addition {
    color: #aaa;
}

.multi-cow-container {
    margin-bottom: 14px;
    display: flex;
}
.multi-cow-container.carcass {
    flex-wrap: wrap;
}
.multi-cow-container:not(:last-child) > .multi-values {
    padding-bottom: 6px;
}

.multi-cow-container:not(:last-child) {
    border-bottom: dashed 1px rgb(221, 221, 221);
}
.multi-cow {
    font-weight: bold;
    color: #666666;
}

.multi-values .slabel {
    margin-right: 2px;
}

.multi-value-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 6px;
}
.multi-value-item .addition {
    margin-left: 6px;
}
.multi-value-item input {
    width: 96px!important;
    margin-right: 4px;
}
.multi-value-item select {
    width: 96px!important;
}

.partner {
    display: flex;
    align-items: center;
}
.partner-select {
    width: 150px!important;
    margin-right: 10px;
}