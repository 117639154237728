
.washout-header {
  font-weight: bold;
}
.washout-header label {
  margin-left: 4px;
}

.washout-term {
  margin: 10px 10px 10px 40px;
}

.washout-collapse {
  margin: 20px 20px 20px 0;
}

.washout-detail  {
  margin: 0 10px 10px 10px;
  border-bottom: 1px solid #ddd;
}

.washout-detail:first-child  {
  margin-top: 10px;
}

.washout-detail:only-child, .washout-detail:last-child  {
  border: none;
  margin-bottom: 0px;
}

.washout-detail-name {
  margin: 10px 10px 10px 10px;
}

.washout-detail-term {
  margin: 10px 10px 10px 30px;
}