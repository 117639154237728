.stock-top {
  display: flex;
  justify-content: space-between;
}
.stock-top .tags {
  display: flex;
  flex-wrap: wrap;
}
.stock-top .tags > div {
  margin-right: 6px;
  font-size: 0.75rem;
}
.stock-top .tags > div > i {
  color: red;
}
.stock-top .tags > div:not(.tag) {
  color: #83aec7;
}

.stock-table-container {
  flex: 1;
  overflow-y: auto;
  min-height: 130px;
  border: solid 1px #dee2e6;
  margin-bottom: 12px;
}

.stock {
  margin-bottom: 0 !important;
  width: 100%;
}

.stock .status.used {
  background-color: #dae8fc;
}

.stock .status.unused {
  background-color: #fff;
}

.stock .status.disposed {
  background-color: #999;
  color: white;
}
.stock .status.sold {
  background-color: rgb(160, 241, 160);
}

.stock .status {
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 0.75rem;
  padding: 4px 0 3px;
  width: 60px;
  text-align: center;
  height: auto;
}

.stock td {
  padding: 11px 0 12px 11px;
}
.stock tr {
  border-bottom: solid 1px #e4e7ea;
}
.stock tr.selected {
  background: #ffe994;
}
.stock tbody {
  width: 100% !important;
}

.stock .td-check {
  width: 1px;
}
.stock .checkbox {
  display: inline !important;
}
.stock .td-status {
  width: 1px;
}
.stock .td-label {
  min-width: 60px;
}
.stock .td-tag {
  padding: 5px;
}
.stock .tag-container {
  display: flex;
  flex-wrap: wrap;
}
.stock .tag {
  background-color: #fff9f9;
  color: #333333;
  cursor: pointer;
  margin: 1px;
  padding: 2px 4px;
}
.stock .tag {
  border: solid 1px #ccc;
  border-radius: 5px;
  font-size: 0.7rem;
}

.stock-bottom {
  min-height: 146px;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}

.selected-stock-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}