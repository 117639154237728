.icon {
    text-shadow: 0 0 2px #222;
}
.icon:not(.disable) {
    cursor: pointer;
}
.icon.on,
.sicon {
    color: #fc0;
}
.icon.off {
    color: white;
}

.sicon {
    font-size: 10px;
    text-shadow: 0 0 1px #222;
}
.sicon.off {
    visibility: hidden;
}
