
.container {
    overflow-x: auto;
    max-width: 720px;
    margin: auto;
    max-height: 280px;
}
.row {
    display: flex;
    margin-bottom: 1px;
}
.row-header {
    flex: 1;
    padding: 4px 4px;
    min-width: 90px;
    display: flex;
}
.check-cow {
    padding-top: 0!important;
    margin-right: 1px;
}
.check-cow > label::before, .check-cow > label::after {
    margin-top:-10px!important;
}
.column-val {
    flex: 1;
    padding: 4px 4px;
    margin-left: 1px;
    min-width: auto;
}
.column-header {
    text-align: center;
}
.column-profit {
    background-color: #9494e3;
    text-align: right;
}
.column-loss {
    background-color: #eeeeff;
    text-align: right;
}
.column-balance {
    background-color: #ffcc55;
    text-align: right;
}
.unselected {
    color: #666666;
    background-color: rgb(182, 182, 182);
    margin-left: 0;
}
.row-sum {
    margin-top: 10px;
}