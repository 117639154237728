.container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.header {
    align-self: stretch;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background: white;
    border-top:1px solid lightgray;
    border-bottom:1px solid lightgray;
    z-index:9;
}

.done {
    text-decoration: line-through #aaa;
    color: #aaa;
}

.has-record {
    color: #83aec7;
}

.chk-status > label {
    padding-left: 20px!important;
}